import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 获取文件类型覆盖度
export function getReviewConfig(projectId) {
  // 这个nodeId与nodePrimaryId区别是什么？
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/reviewConfigs`,
    method: 'get'
  })
}

export function addReviewConfig(projectId, data) {
  // 这个nodeId与nodePrimaryId区别是什么？
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/reviewConfigs`,
    data,
    method: 'post'
  })
}

export function deleteReviewConfig(projectId, reviewConfigId) {
  // 这个nodeId与nodePrimaryId区别是什么？
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/reviewConfigs/${reviewConfigId}`,
    method: 'delete'
  })
}

export function updateReviewConfig(projectId, reviewConfigId, data) {
  // 这个nodeId与nodePrimaryId区别是什么？
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/reviewConfigs/${reviewConfigId}`,
    data,
    method: 'put'
  })
}

export function getReceivingReviewList(projectId, params) {
  // 这个nodeId与nodePrimaryId区别是什么？
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ReviewProcess/receiving`,
    params,
    method: 'get'
  })
}

export function getSendingReviewList(projectId, params) {
  // 这个nodeId与nodePrimaryId区别是什么？
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ReviewProcess/sending`,
    params,
    method: 'get'
  })
}

export function getReviewingReviewList(projectId, params) {
  // 这个nodeId与nodePrimaryId区别是什么？
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ReviewProcess`,
    params,
    method: 'get'
  })
}

export function getnodeReview(projectId, data) {
  return request({
    url: `/${serviceConfig['mindmap-service']}/${projectId}/nodeReview/analyze`,
    data,
    method: 'post',
  })
}

export function getNodeReviewThrough(projectId, params, data) {
  return request({
    url: `/${serviceConfig['mindmap-service']}/${projectId}/nodeReview/analyze/through`,
    params,
    data,
    method: 'post'
  })
}

export function getNodeReviewTime(projectId, data) {
  return request({
    url: `/${serviceConfig['mindmap-service']}/${projectId}/nodeReview/time`,
    data,
    method: 'post'
  })
}

export function deleteReviewTask(projectId, reviewProcessId) {
  // 这个nodeId与nodePrimaryId区别是什么？
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ReviewProcess/${reviewProcessId}`,
    method: 'delete'
  })
}

export function endReviewTask(projectId, reviewProcessId) {
  // 这个nodeId与nodePrimaryId区别是什么？
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ReviewProcess/${reviewProcessId}/ending`,
    method: 'put'
  })
}

export function tipReviewTask(projectId, reviewProcessId) {
  // 这个nodeId与nodePrimaryId区别是什么？
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ReviewProcess/${reviewProcessId}/notification`,
    method: 'post'
  })
}


export function getNodeReviewInfo(projectId, nodeKey) {
  // 这个nodeId与nodePrimaryId区别是什么？
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ReviewProcess/nodeKey/${nodeKey}`,
    method: 'get'
  })
}

export function getUnhandleTask(projectId) {
  // 这个nodeId与nodePrimaryId区别是什么？
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ReviewProcess/receiving/count`,
    method: 'get'
  })
}

export function getFileIdInReviewing(projectId) {
  // 这个nodeId与nodePrimaryId区别是什么？
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/reviewingFileTypes`,
    method: 'get'
  })
}



